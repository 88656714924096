<template>
  <div class="login-register-page">
    <div :class="{'taller': tallerContainer, 'loading': loading}" class="login-main-container">
      <div v-if="loading" class="loading-screen">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
          <path d="M30 4C31.1046 4 32 3.10457 32 2C32 0.89543 31.1046 0 30 0V4ZM56 30C56 44.3594 44.3594 56 30 56V60C46.5685 60 60 46.5685 60 30H56ZM30 56C15.6406 56 4 44.3594 4 30H0C0 46.5685 13.4315 60 30 60V56ZM4 30C4 15.6406 15.6406 4 30 4V0C13.4315 0 0 13.4315 0 30H4Z" fill="url(#gradient)">
            <animateTransform attributeName="transform" type="rotate" from="0 30 30" to="360 30 30" dur="1s" repeatCount="indefinite" />
          </path>
          <defs>
            <radialGradient id="gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse">
              <stop offset="0.25" stop-color="#fff"/>
              <stop offset="1" stop-color="#1C1C1D"/>
            </radialGradient>
          </defs>
        </svg>
      </div>
      <header>
        <transition name="normalFade">
          <button v-if="goBackVisible" @click="goBack(previousScreen)" class="return-button">
            <img src="./assets/arrow_left.png" alt="Назад">
          </button>
        </transition>
        <img :class="{'hidden': !headerVisible}" class="header-image" :src="require('./assets/101.png')" alt="101">
      </header>
      <transition :name="returnTransition">
        <div v-if="currentScreen == 'main'" key="main" class="login-container">
          <ValidationObserver style="height: 100%" v-slot="{ handleSubmit, reset }">
            <form ref="loginForm" @submit.prevent="handleSubmit(handleContinue)" @reset.prevent="reset" class="login-form active-content">
              <div class="upper">
                <div class="header">
                  <div class="heading">Войти или создать учётную запись 101</div>
                </div>
                <div class="tabs">
                  <button class="tab-button" type="reset" :class="{'active-tab': checkTab('email')}" @click="changeTab($event)">Электронная почта</button>
                  <button class="tab-button" type="reset" :class="{'active-tab': checkTab('phone')}" @click="changeTab($event)">Телефон</button>
                </div>
                <div v-if="currentTab === 'email'" :class="{'typed': email !== ''}" class="input-holder">
                  <ValidationProvider ref="emailLoginProvider" v-if="currentTab === 'email'" mode="passive" rules="required|email" v-slot="{ errors, classes }">
                    <input id="emailLoginInput" ref="emailLoginInput" :class="classes" :disabled="currentTab === 'phone'" v-model="email" placeholder="Email" type="text">
                    <div v-show="email !== ''" @click="clearEmailInput" class="clear-input">
                      <img src="./assets/close.png" alt="Очистить поле">
                    </div>
                    <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div v-if="currentTab === 'phone'" :class="{'typed': phoneNumber !== ''}" class="input-holder">
                  <ValidationProvider  ref="phoneLoginProvider" mode="passive" rules="phone|required" v-slot="{ errors, classes }">
                    <imask-input
                    ref="phoneLoginInput"
                    :class="classes"
                    v-model="phoneNumber"
                    name="phone-number"
                    mask="+7 000 000 00 00"
                    :unmask="true"
                    placeholder='+7 000 000 00 00'
                    />
                    <div v-show="phoneNumber !== ''" @click="clearPhoneInput" class="clear-input">
                      <img src="./assets/close.png" alt="Очистить поле">
                    </div>
                    <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div :class="{'loading': loading}" class="submit-button">
                <button :class="{'disabled': loading}" class="regular">Продолжить</button>
                <!-- <button type="button" class="loading">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A"/></svg>
                </button> -->
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'emailPassword'" key="emailPassword" class="login-container auth">
          <ValidationObserver style="height: 100%" v-slot="{ handleSubmit, reset }">
          <form ref="emailPasswordForm" @reset.prevent="reset" @submit.prevent="handleSubmit(handleLogin)" class="login-form" :class="{'active-content': checkTab('email')}">
          <div>
            <div class="header">
              <div class="heading">Введите пароль</div>
              <div class="description">Чтобы войти в учётную запись <b>{{ email }}</b></div>
            </div>
            <ValidationProvider mode="passive" rules="verifyPassword:@password|required" v-slot="{ errors, classes }">
              <div class="input-holder password-holder">
                <input id="emailPasswordInput" autocomplete="current-password" :class="classes" v-model="password" placeholder="Пароль" :type="showPassword ? 'text' : 'password'">
                <div @click="showPassword = !showPassword" class="show-password">
                  <img v-show="!showPassword" src="./assets/show.png" alt="Показать пароль">
                  <img v-show="showPassword" src="./assets/show_noshow.png" alt="Показать пароль">
                </div>
                <span class="validation-error" v-if="isWrongPassword">{{ errorMessage }}</span>
                <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider style="display: none" name="password">
              <input v-model="passwordToVerify" type="text">
            </ValidationProvider>
          </div>
          <div :class="{'loading': loading}" class="submit-button buttons">
            <button class="transparent blue" @click="handleForgotPassword" type="reset">Не помню пароль</button>
            <div>
              <button :class="{'disabled': loading}" class="regular" type="submit">Продолжить</button>
              <!-- <button type="button" class="loading">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A"/></svg>
                </button> -->
            </div>
          </div>
          </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'phonePassword'" key="phonePassword" class="login-container auth">
          <ValidationObserver style="height: 100%" v-slot="{ handleSubmit, reset }">
          <form ref="phonePasswordForm" @reset.prevent="reset" @submit.prevent="handleSubmit(handleLogin)" class="login-form" :class="{'active-content': checkTab('phone')}">
            <div>
              <div class="header">
                <div class="heading">Введите пароль</div>
                <div class="description">Чтобы войти в учётную запись <b>{{ filteredPhoneNumber }}</b></div>
              </div>
              <ValidationProvider mode="passive" rules="verifyPassword:@password|required" v-slot="{ errors, classes }">
                <div class="input-holder">
                  <input id="phonePasswordInput" :class="classes" v-model="password" placeholder="Пароль" :type="showPassword ? 'text' : 'password'">
                  <div v-show="password !== ''" @click="showPassword = !showPassword" class="show-password">
                    <img v-show="!showPassword" src="./assets/show.png" alt="Показать пароль">
                    <img v-show="showPassword" src="./assets/show_noshow.png" alt="Показать пароль">
                  </div>
                  <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                  <span class="validation-error" v-if="isWrongPassword">{{ errorMessage }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider style="display: none" name="password">
                <input v-model="passwordToVerify" type="text">
              </ValidationProvider>
            </div>
            <div class="submit-button buttons">
              <button class="transparent blue" @click="handleForgotPassword" type="reset">Не помню пароль</button>
              <button type="submit">Продолжить</button>
            </div>
          </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'passwordRecoveryEmailCode'" key="passwordRecoveryEmailCode" class="login-container">
          <ValidationObserver ref="emailPasswordRecoveryObserver" style="height: 100%" v-slot="{ handleSubmit, reset }">
            <form ref="emailRecoveryForm" id="email-recovery" @reset.prevent="reset" @submit.prevent="handleSubmit(handleRecoveryCodeContinue)" class="login-form" :class="{'active-content': checkTab('email')}">
              <div>
                <div class="header">
                  <div class="heading">Введите код из письма</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator disabled"></div>
                  </div> -->
                  <div v-if="counting" class="description">На&nbsp;вашу почту {{ email }} был направлен код подтверждения. Обычно письмо приходит в&nbsp;течение минуты. Если письмо не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода через <countdown :transform="transform" v-if="counting" :time="countingTime" @end="onCountdownEnd" v-slot="{ totalSeconds, totalMinutes, seconds, minutes }"> <span style="color: #007AFF">{{ minutes }}:{{ seconds }}</span></countdown></div>
                  <div v-if="!counting" class="description">На&nbsp;вашу почту {{ email }} был направлен код подтверждения. Обычно письмо приходит в&nbsp;течение минуты. Если письмо не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода</div>
                </div>
                <ValidationProvider mode="passive" rules="verifyCode:@code|required" v-slot="{ errors, classes }">
                  <div class="input-holder">
                    <v-otp-input
                      class="otp-code"
                      :class="classes"
                      v-model="verificationCode"
                      ref="emailPasswordRecoveryOtpInput"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="onVerificationCodeChange($event, '#email-recovery')"
                    />
                    <!-- <CodeInput ref="emailRecoveryCode" :class="classes" :fieldWidth="60" :fields="6" v-on:change="onVerificationCodeChange($event, '#email-recovery')" v-model="verificationCode" :autoFocus="false" :loading="false" class="login-code" /> -->
                    <span class="validation-error" v-if="isWrongCode">{{ errorMessage }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider style="display: none" name="code">
                  <input v-model="codeToVerify" type="text">
                </ValidationProvider>
                <div class="send-again-button">
                  <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                </div>
              </div>
              <!-- <div class="submit-button" :class="{ 'buttons': !counting }">
                <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                <button type="submit">Продолжить</button>
              </div> -->
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'passwordRecoveryPhoneCode'" key="passwordRecoveryPhoneCode" class="login-container">
          <ValidationObserver ref="phonePasswordRecoveryObserver" style="height: 100%" v-slot="{ handleSubmit }">
            <form id="phone-recovery" @submit.prevent="handleSubmit(handleRecoveryCodeContinue)" class="login-form" :class="{'active-content': checkTab('phone')}">
              <div>
                <div class="header">
                  <div class="heading">Введите код из SMS</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator disabled"></div>
                  </div> -->
                  <div v-if="counting" class="description">На&nbsp;ваш номер телефона {{ filteredPhoneNumber }} был направлен код подтверждения. Обычно смс приходит в&nbsp;течение минуты. Если смс не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода через <countdown :transform="transform" v-if="counting" :time="countingTime" @end="onCountdownEnd" v-slot="{ totalSeconds, totalMinutes, seconds, minutes }"> <span style="color: #007AFF">{{ minutes }}:{{ seconds }}</span></countdown></div>
                  <div v-if="!counting" class="description">На&nbsp;ваш номер телефона {{ filteredPhoneNumber }} был направлен код подтверждения. Обычно смс приходит в&nbsp;течение минуты. Если смс не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода</div>
                </div>
                <ValidationProvider mode="passive" rules="verifyPassword:@code|required" v-slot="{ errors, classes }">
                  <div class="input-holder">
                    <v-otp-input
                      class="otp-code"
                      :class="classes"
                      v-model="verificationCode"
                      ref="phonePasswordRecoveryOtpInput"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="onVerificationCodeChange($event, '#phone-recovery')"
                    />
                    <!-- <CodeInput ref="phoneRecoveryCode" :class="classes" :fieldWidth="60" :fields="6" v-on:change="onVerificationCodeChange($event, '#phone-recovery')" v-model="verificationCode" :autoFocus="false" :loading="false" class="login-code" /> -->
                    <!-- <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span> -->
                    <span class="validation-error" v-if="isWrongCode">{{ errorMessage }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider style="display: none" name="code">
                  <input v-model="codeToVerify" type="text">
                </ValidationProvider>
                <div class="send-again-button">
                  <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                </div>
              </div>
              <!-- <div class="submit-button" :class="{ 'buttons': !counting }">
                <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                <button type="submit">Продолжить</button>
              </div> -->
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'passwordRecoveryNewPassword'" key="passwordRecoveryNewPassword" class="login-container">
          <ValidationObserver style="height: 100%" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(handleNewPassword)" class="login-form active-content">
              <div>
                <div class="header">
                  <div class="heading">Придумайте новый пароль</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator"></div>
                  </div> -->
                </div>
                <div class="register-inputs">
                  <div class="input-holder">
                    <ValidationProvider rules="required|minPassword" v-slot="{ errors, classes }">
                      <input :class="classes" v-model="password" placeholder="Введите пароль" :type="showPassword ? 'text' : 'password'">
                      <div v-show="password !== ''" @click="showPassword = !showPassword" class="show-password">
                        <img v-show="!showPassword" src="./assets/show.png" alt="Показать пароль">
                        <img v-show="showPassword" src="./assets/show_noshow.png" alt="Показать пароль">
                      </div>
                      <div v-if="password !== ''" class="password-meter">
                        <div :class="{'weak': !checkPasswordLength, 'good': checkPasswordLength}" class="indicator"></div>
                      </div>
                      <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                      <span class="validation-error success" v-if="password.length >= 6">Пароль надёжный</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="submit-button">
                <button type="submit">Продолжить</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'passwordRecoverySuccess'" key="passwordRecoverySuccess" class="login-container">
          <div class="register-success">
            <div class="header">
              <img class="success-image" src="./assets/checkmark.png" alt="">
              <div class="success-text">
                <div class="success-heading">Пароль успешно изменён</div>
                <!-- <div class="success-description">На&nbsp;вашу почту было отправлено письмо с&nbsp;подтверждением</div> -->
              </div>
            </div>
            <div class="submit-button success">
              <button @click="handleRegisterSuccessContinue">Окей</button>
            </div>
          </div>
          <!-- <div class="submit-button success">
            <button>Окей</button>
          </div> -->
        </div>
        <div v-if="currentScreen == 'emailCode'" key="emailCode" class="login-container">
          <ValidationObserver ref="emailCodeObserver" style="height: 100%" v-slot="{ handleSubmit }">
            <form id="email-register" @submit.prevent="handleSubmit(handleCodeContinue)" class="login-form" :class="{'active-content': checkTab('email')}">
              <div>
                <div class="header">
                  <div class="heading">Введите код из письма</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator disabled"></div>
                  </div> -->
                  <div v-if="counting" class="description">На&nbsp;вашу почту {{ email }} был направлен код подтверждения. Обычно письмо приходит в&nbsp;течение минуты. Если письмо не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода через <countdown :transform="transform" v-if="counting" :time="countingTime" @end="onCountdownEnd" v-slot="{ totalSeconds, totalMinutes, seconds, minutes }"> <span style="color: #007AFF">{{ minutes }}:{{ seconds }}</span></countdown></div>
                  <div v-if="!counting" class="description">На&nbsp;вашу почту {{ email }} был направлен код подтверждения. Обычно письмо приходит в&nbsp;течение минуты. Если письмо не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода</div>
                </div>
                <ValidationProvider mode="passive" rules="verifyCode:@code|required" v-slot="{ errors, classes }">
                  <div class="input-holder">
                    <v-otp-input
                      class="otp-code"
                      :class="classes"
                      v-model="verificationCode"
                      ref="emailRegisterOtpInput"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="onVerificationCodeChange($event, '#email-register')"
                    />
                    <!-- <CodeInput ref="emailRegisterCode" :class="classes" :fieldWidth="60" :autoFocus="false" :fields="6" v-on:change="onVerificationCodeChange($event, '#email-register')" @paste="console.log('paste')" v-model="verificationCode" :loading="false" class="login-code" /> -->
                    <span class="validation-error" v-if="isWrongCode">{{ errorMessage }}</span>
                    <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider style="display: none" name="code">
                  <input v-model="codeToVerify" type="text">
                </ValidationProvider>
                <div class="send-again-button">
                  <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                </div>
              </div>
              <!-- <div class="submit-button" :class="{ 'buttons': !counting }">
                <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                <button class="submit-button" id="email-register-submit" type="submit">Продолжить</button>
              </div> -->
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'phoneCode'" key="phoneCode" class="login-container">
          <ValidationObserver ref="phoneCodeObserver" style="height: 100%" v-slot="{ handleSubmit }">
            <form id="phone-register" @submit.prevent="handleSubmit(handleCodeContinue)" class="login-form" :class="{'active-content': checkTab('phone')}">
              <div>
                <div class="header">
                  <div class="heading">Введите код из SMS</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator disabled"></div>
                  </div> -->
                  <div v-if="counting" class="description">На&nbsp;ваш номер телефона {{ filteredPhoneNumber }} был направлен код подтверждения. Обычно смс приходит в&nbsp;течение минуты. Если смс не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода через <countdown :transform="transform" v-if="counting" :time="countingTime" @end="onCountdownEnd" v-slot="{ totalSeconds, totalMinutes, seconds, minutes }"> <span style="color: #007AFF">{{ minutes }}:{{ seconds }}</span></countdown></div>
                  <div v-if="!counting" class="description">На&nbsp;ваш номер телефона {{ filteredPhoneNumber }} был направлен код подтверждения. Обычно смс приходит в&nbsp;течение минуты. Если смс не&nbsp;пришло&nbsp;&mdash; запросите повторную отправку кода</div>
                </div>
                <ValidationProvider mode="passive" rules="verifyCode:@code|required" v-slot="{ errors, classes }">
                  <div class="input-holder">
                    <v-otp-input
                      class="otp-code"
                      :class="classes"
                      v-model="verificationCode"
                      ref="phoneRegisterOtpInput"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="onVerificationCodeChange($event, '#phone-register')"
                    />
                    <!-- <CodeInput ref="phoneRegisterCode" :class="classes" :fieldWidth="60" :fields="6" v-on:change="onVerificationCodeChange($event, '#phone-register')" v-model="verificationCode" :autoFocus="false" :loading="false" class="login-code" /> -->
                    <span class="validation-error" v-if="isWrongCode">{{ errorMessage }}</span>
                    <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider style="display: none" name="code">
                  <input v-model="codeToVerify" type="text">
                </ValidationProvider>
                <div class="send-again-button">
                  <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                </div>
              </div>
              <!-- <div class="submit-button" :class="{ 'buttons': !counting }">
                <button class="transparent" v-if="!counting" @click="handleSendAgain" type="button">Отправить заново</button>
                <button type="submit">Продолжить</button>
              </div> -->
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'register'" key="register" class="login-container taller">
          <ValidationObserver style="height: 100%" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(handleRegisterContinue)" class="login-form active-content">
              <div>
                <div class="header">
                  <div class="heading">Введите данные для регистрации</div>
                  <!-- <div class="progress-indication">
                    <div class="indicator"></div>
                    <div class="indicator"></div>
                  </div> -->
                </div>
                <div class="register-inputs-container">
                  <div class="register-inputs">
                    <div class="input-holder">
                      <ValidationProvider mode="eager" rules="required|alpha" v-slot="{ errors, classes }">
                        <input autocomplete="given-name" :class="classes" v-model="firstName" placeholder="Ваше имя" type="text">
                        <div v-show="firstName !== ''" @click="firstName = ''" class="clear-input">
                          <img src="./assets/close.png" alt="Очистить поле">
                        </div>
                        <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="input-holder">
                      <ValidationProvider mode="eager" rules="required|alpha" v-slot="{ errors, classes }">
                        <input autocomplete="family-name" :class="classes" v-model="lastName" placeholder="Ваша фамилия" type="text">
                        <div v-show="lastName !== ''" @click="lastName = ''" class="clear-input">
                          <img src="./assets/close.png" alt="Очистить поле">
                        </div>
                        <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="input-holder">
                      <ValidationProvider rules="required|minPassword" v-slot="{ errors, classes }">
                        <input autocomplete="new-password" id="last-register-input" :class="classes" v-model="password" placeholder="Придумайте пароль" :type="showPassword ? 'text' : 'password'">
                        <div v-show="password !== ''" @click="showPassword = !showPassword" class="show-password">
                          <img v-show="!showPassword" src="./assets/show.png" alt="Показать пароль">
                          <img v-show="showPassword" src="./assets/show_noshow.png" alt="Показать пароль">
                        </div>
                        <div v-if="password !== ''" class="password-meter">
                        <div :class="{'weak': !checkPasswordLength, 'good': checkPasswordLength}" class="indicator"></div>
                        </div>
                        <span class="validation-error caption" v-if="password.length === 0">Не менее 6 символов</span>
                        <span class="validation-error" v-if="errors.length">{{ errors[0] }}</span>
                        <span class="validation-error success" v-if="password.length >= 6">Пароль надёжный</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div style="margin-top: 16px" class="submit-button">
                    <button type="submit">Зарегистрироваться</button>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <!-- <div class="submit-button">
                  <button type="submit">Зарегистрироваться</button>
                </div> -->
                <div class="policy-info">
                  Нажимая Зарегистрироваться, вы&nbsp;принимаете <a target="_blank" href="https://101-app.com/privacy">Политику конфиденциальности</a> и&nbsp;<a target="_blank" href="https://101-app.com/consent">Согласие на&nbsp;обработку персональных данных</a> и&nbsp;подтверждаете наличие у&nbsp;вашей компании Политики обработки персональных данных и&nbsp;соблюдение законодательства в&nbsp;области персональных данных
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div v-if="currentScreen == 'registerSuccess'" key="registerSuccess" class="login-container">
          <div class="register-success">
            <div class="header">
              <img class="success-image" src="./assets/checkmark.png" alt="">
              <div class="success-text">
                <div class="success-heading">Регистрация прошла успешно</div>
                <div class="success-description">Теперь вы&nbsp;можете пользоваться всеми продуктами&nbsp;101&nbsp;с одной учётной записью</div>
              </div>
            </div>
            <div class="submit-button success">
              <button @click="handleRegisterSuccessContinue">Отлично</button>
            </div>
          </div>
          <!-- <div class="submit-button success">
            <button>Отлично</button>
          </div> -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask'
import './vee-validate'
import { apiClient } from '@/services/apiClient';
import { mapActions } from 'vuex';

export default {
data () {
  return {
    loading: false,
    transitionDirection: 'forward',
    headerVisible: true,
    goBackVisible: false,
    previousScreen: null,
    tallerContainer: false,
    currentScreen: 'main',
    currentTab: 'email',
    verificationCode: '',
    codeToVerify: '123456',
    phoneNumber: '',
    phoneExists: false,
    firstName: '',
    lastName: '',
    email: '',
    emailExists: false,
    accountsList: [
      { email: 'thezibun@gmail.com', phoneNumber: '9999999999' },
      { email: 'test@mail.su', phoneNumber: '9879999999' }
    ],
    password: '',
    passwordToVerify: '123456',
    counting: false,
    score: null,
    showPassword: false,
    countingTime: 120000,
    hasInvalid: false,
    errorMessage: '',
    isWrongPassword: false,
    isWrongCode: false,
    processGuid: null,
    codeProcessGuid: null
  }
},
methods: {
  ...mapActions('auth', ['AUTH_REQUEST']),
  changeTab (event) {
    if (event.srcElement.innerText === 'Электронная почта') {
        if (this.currentTab !== 'email') {
            this.currentTab = 'email'
            if (this.phoneNumber !== '') {
              this.phoneNumber = ''
            }
        }
    } else if (event.srcElement.innerText === 'Телефон') {
        if (this.currentTab !== 'phone') {
            this.currentTab = 'phone'
            if (this.email !== '') {
              this.email = ''
            }
        }
    }
  },
  goBack (name) {
    this.transitionDirection = 'backwards'

    this.currentScreen = name

    if (name === 'main') {
      this.goBackVisible = false
      this.tallerContainer = false
      this.isWrongPassword = false
      this.isWrongCode = false
      this.password = ''
      this.showPassword = false
    } else if (name === 'emailPassword' || name === 'phonePassword') {
      this.previousScreen = 'main'
    }
  },
  checkTab (tab) {
    return this.currentTab === tab
  },
  onVerificationCodeChange (input, query) {
    this.verificationCode = input

    if (this.verificationCode.length === 6) {
      setTimeout(() => {
        document.querySelector(query).requestSubmit()
      }, 200);

      if (this.verificationCode !== this.codeToVerify) {
        setTimeout(() => {
          if (this.currentScreen === 'emailCode') {
            this.$refs.emailRegisterOtpInput.clearInput()
            // this.$refs.emailRegisterCode.values = [
            //   '', '', '', '', '', ''
            // ]

            // const name = this.$refs.emailRegisterCode.iRefs[0];
            // const element = this.$refs.emailRegisterCode.$refs[name][0];
            // element.focus();
            // element.select();
          } else if (this.currentScreen === 'phoneCode') {
            this.$refs.phoneRegisterOtpInput.clearInput()
            // this.$refs.phoneRegisterCode.values = [
            //   '', '', '', '', '', ''
            // ]

            // const name = this.$refs.phoneRegisterCode.iRefs[0];
            // const element = this.$refs.phoneRegisterCode.$refs[name][0];
            // element.focus();
            // element.select();
          } else if (this.currentScreen === 'passwordRecoveryEmailCode') {
            this.$refs.emailPasswordRecoveryOtpInput.clearInput()
            // this.$refs.emailRecoveryCode.values = [
            //   '', '', '', '', '', ''
            // ]

            // const name = this.$refs.emailRecoveryCode.iRefs[0];
            // const element = this.$refs.emailRecoveryCode.$refs[name][0];
            // element.focus();
            // element.select();
          } else if (this.currentScreen === 'passwordRecoveryPhoneCode') {
            this.$refs.phonePasswordRecoveryOtpInput.clearInput()
            // this.$refs.phoneRecoveryCode.values = [
            //   '', '', '', '', '', ''
            // ]

            // const name = this.$refs.phoneRecoveryCode.iRefs[0];
            // const element = this.$refs.phoneRecoveryCode.$refs[name][0];
            // element.focus();
            // element.select();
          }
          this.verificationCode = ''
        }, 2000);
      }
    }
  },
  async handleSendAgain () {
    this.counting = true;
    this.countingTime = 120000;

    const login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const channelType = this.currentTab;

    await this.requestCode(login, channelType);

    if (this.currentScreen === 'emailCode') {
      this.$refs.emailCodeObserver.reset();
      this.$refs.emailRegisterOtpInput.clearInput()
      // this.$refs.emailRegisterCode.values = ['', '', '', '', '', ''];
      // const name = this.$refs.emailRegisterCode.iRefs[0];
      // const element = this.$refs.emailRegisterCode.$refs[name][0];
      // element.focus();
      // element.select();
    } else if (this.currentScreen === 'phoneCode') {
      this.$refs.phoneCodeObserver.reset();
      this.$refs.phoneRegisterOtpInput.clearInput()
      // this.$refs.phoneRegisterCode.values = ['', '', '', '', '', ''];
      // const name = this.$refs.phoneRegisterCode.iRefs[0];
      // const element = this.$refs.phoneRegisterCode.$refs[name][0];
      // element.focus();
      // element.select();
    } else if (this.currentScreen === 'passwordRecoveryEmailCode') {
      this.$refs.emailPasswordRecoveryObserver.reset();
      this.$refs.emailPasswordRecoveryOtpInput.clearInput()
      // this.$refs.emailRecoveryCode.values = ['', '', '', '', '', ''];
      // const name = this.$refs.emailRecoveryCode.iRefs[0];
      // const element = this.$refs.emailRecoveryCode.$refs[name][0];
      // element.focus();
      // element.select();
    } else if (this.currentScreen === 'passwordRecoveryPhoneCode') {
      this.$refs.phonePasswordRecoveryObserver.reset();
      this.$refs.phonePasswordRecoveryOtpInput.clearInput()
      // this.$refs.phoneRecoveryCode.values = ['', '', '', '', '', ''];
      // const name = this.$refs.phoneRecoveryCode.iRefs[0];
      // const element = this.$refs.phoneRecoveryCode.$refs[name][0];
      // element.focus();
      // element.select();
    }

    this.verificationCode = '';
  },
  handlePhoneContinue () {
    for (var i = 0; i < this.accountsList.length; ++i) {
      const e = this.accountsList[i]

      if (e.phoneNumber === this.phoneNumber) {
        this.phoneExists = true
        this.currentScreen = 'phonePassword'
        break
      } else if (e.phoneNumber !== this.phoneNumber) {
        this.currentScreen = 'phoneCode'
        this.counting = true
      }
    }
  },
  async checkLoginExistence (login) {
    const isPhoneNumber = /^\d+$/.test(login);

    if (isPhoneNumber && !login.startsWith('+7')) {
      login = `+7${login}`;
    }

    try {
      const response = await apiClient.checkLogin({ login });
      return response.data.result;
    } catch (error) {
      return false;
    }
  },
  async createProcess () {
    const response = await apiClient.createProcess({ processType: 'register' });
    this.processGuid = response.data.guid;
  },
  async requestCode (login, channelType) {
    const isPhoneNumber = /^\d+$/.test(login);

    if (isPhoneNumber && !login.startsWith('+7')) {
      login = `+7${login}`;
    }

    const response = await apiClient.requestCode(login, {
      headers: {
        processId: this.processGuid,
        channelType: channelType,
        login: login
      }
    });
    this.codeProcessGuid = response.data.guid;
  },
  async verifyCode (enteredCode) {
    this.isWrongCode = true
    this.errorMessage = ''

    try {
      const response = await apiClient.verifyCode({
        headers: {
          codeRequestId: this.codeProcessGuid,
          code: enteredCode
        }
      });
      return response.data;
    } catch (error) {
      this.isWrongCode = true
      this.errorMessage = 'Неверный код. Повторите попытку или запросите повторную отправку кода';
      throw error;
    }
  },
  async handleContinue () {
    this.loading = true
    const login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const loginExists = await this.checkLoginExistence(login);

    this.transitionDirection = 'forward'

    if (loginExists) {
      if (this.currentTab === 'email') {
        this.emailExists = true;
        this.loading = false
        this.currentScreen = 'emailPassword';
        this.previousScreen = 'main'
        this.goBackVisible = true
      } else if (this.currentTab === 'phone') {
        this.phoneExists = true;
        this.loading = false
        this.currentScreen = 'phonePassword';
        this.previousScreen = 'main'
        this.goBackVisible = true
      }
    } else {
      await this.createProcess();
      await this.requestCode(login, this.currentTab);
      this.loading = false
      this.currentScreen = this.currentTab === 'email' ? 'emailCode' : 'phoneCode';
      this.previousScreen = 'main'
      this.goBackVisible = true
      this.counting = true;
    }
  },
  async handleCodeContinue () {
    const enteredCode = this.verificationCode;
    this.isWrongCode = true
    this.errorMessage = ''
    this.loading = true

    try {
      const result = await this.verifyCode(enteredCode);

      if (result.result.verified) {
        this.tallerContainer = true
        this.loading = false
        this.currentScreen = 'register';
        this.counting = false;
      } else {
        this.loading = false
        this.isWrongCode = true
        this.errorMessage = 'Неверный код. Повторите попытку или запросите повторную отправку кода';
      }
    } catch (error) {
      this.loading = false
      this.isWrongCode = true
      this.errorMessage = 'Неверный код. Повторите попытку или запросите повторную отправку кода'
    }
  },
  async handleLogin () {
    this.loading = true
    let login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const isPhoneNumber = /^\d+$/.test(login);

    if (isPhoneNumber && !login.startsWith('+7')) {
      login = `+7${login}`;
    }
    const password = this.password;
    this.isWrongPassword = false;
    this.errorMessage = '';

    try {
      await this.AUTH_REQUEST({ login, password });

      const companies = await apiClient.fetchCompanies();
      if (companies.data.length === 0) {
        this.$router.push('/subscription');
        this.loading = false
      } else {
        this.$router.push('/services');
        this.loading = false
      }
    } catch (error) {
      if (error.response && error.response.data['1002']) {
        this.loading = false
        this.isWrongPassword = true;
        this.errorMessage = 'Неверный пароль. Повторите попытку или нажмите на\u00a0ссылку «Не\u00a0помню пароль», чтобы сбросить его';
      } else {
        this.loading = false
        this.isWrongPassword = true;
        this.errorMessage = 'Неверный пароль. Повторите попытку или нажмите на\u00a0ссылку «Не\u00a0помню пароль», чтобы сбросить его';
      }
    }
  },
  async createAccount (login, processGuid, password, firstName, lastName) {
    const isPhoneNumber = /^\d+$/.test(login);

    if (isPhoneNumber && !login.startsWith('+7')) {
      login = `+7${login}`;
    }

    const response = await apiClient.createAccount({
      login: login,
      processId: processGuid,
      password: password,
      firstName: firstName,
      lastName: lastName
    });
    return response.data;
  },
  async handleRegisterContinue () {
    this.loading = true
    const login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const { firstName, lastName, password } = this;
    await this.createAccount(login, this.processGuid, password, firstName, lastName);
    this.loading = false
    this.goBackVisible = false
    this.headerVisible = false
    this.tallerContainer = false
    this.currentScreen = 'registerSuccess';
  },
  handleRegisterSuccessContinue () {
    this.firstName = ''
    this.lastName = ''
    this.password = ''
    this.phoneNumber = ''
    this.email = ''
    this.codeProcessGuid = null;
    this.processGuid = null;
    this.headerVisible = true
    this.currentScreen = 'main'
  },
  async handleForgotPassword () {
    this.counting = true;
    this.countingTime = 120000;
    this.loading = true
    this.isWrongCode = false
    this.transitionDirection = 'forward'

    const login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const channelType = this.currentTab;

    const response = await apiClient.createProcess({ processType: 'restore' });
    this.processGuid = response.data.guid;

    await this.requestCode(login, channelType);

    if (this.currentTab === 'email') {
      this.loading = false
      this.currentScreen = 'passwordRecoveryEmailCode';
      this.previousScreen = 'emailPassword'
    } else if (this.currentTab === 'phone') {
      this.loading = false
      this.currentScreen = 'passwordRecoveryPhoneCode';
      this.previousScreen = 'phonePassword'
    }
  },
  async handleRecoveryCodeContinue () {
    const enteredCode = this.verificationCode;
    this.isWrongCode = false;
    this.errorMessage = '';
    this.loading = true

    try {
      const result = await this.verifyCode(enteredCode);

      if (result.result.verified) {
        this.loading = false
        this.currentScreen = 'passwordRecoveryNewPassword';
      } else {
        this.loading = false
        this.isWrongCode = true;
        this.errorMessage = 'Неверный код. Повторите попытку или запросите повторную отправку кода';
      }
    } catch (error) {
      this.loading = false
      this.isWrongCode = true;
      this.errorMessage = 'Неверный код. Повторите попытку или запросите повторную отправку кода';
    }
  },
  async handleNewPassword () {
    let login = this.currentTab === 'email' ? this.email : this.phoneNumber;
    const isPhoneNumber = /^\d+$/.test(login);
    this.loading = true

    if (isPhoneNumber && !login.startsWith('+7')) {
      login = `+7${login}`;
    }
    const newPassword = this.password;
    const processId = this.processGuid;

    try {
      await apiClient.restorePassword({
        login: login,
        processId: processId,
        password: newPassword
      });
      this.goBackVisible = false
      this.headerVisible = false
      this.loading = false
      this.currentScreen = 'passwordRecoverySuccess';
    } catch (error) {
      this.loading = false
      this.errorMessage = 'Ошибка при восстановлении пароля. Пожалуйста, попробуйте еще раз.';
    }
  },
  startCountdown: function () {
      this.counting = true;
  },
  onCountdownEnd: function () {
    this.counting = false;
  },
  transform (props) {
    Object.entries(props).forEach(([key, value]) => {
      // Adds leading zero
      const digits = value < 10 ? `0${value}` : value;

      props[key] = `${digits}`;
    });

    return props;
  },
  onScore ({ score }) {
    this.score = score;
  },
  clearEmailInput () {
    this.email = ''
    this.$refs.emailLoginProvider.reset()
  },
  clearPhoneInput () {
    this.phoneNumber = ''
    this.$refs.phoneLoginProvider.reset()
  },
  onClassChange (classAttrValue) {
    const classList = classAttrValue.split(' ');
    if (classList.includes('invalid')) {
      this.hasInvalid = true
    }
  },
  callObserver () {
    setTimeout(() => {
      this.observer.observe(document.querySelector('#last-register-input'), {
          attributes: true,
          attributeOldValue: true,
          attributeFilter: ['class']
      })
    }, 1000)
  }
},
computed: {
  filteredPhoneNumber () {
    return '+7' + '\xa0' + this.phoneNumber.slice(0, 3) + '\xa0' + this.phoneNumber.slice(3, 6) + '\xa0' + this.phoneNumber.slice(6, 8) + '' + this.phoneNumber.slice(8, 10)
  },
  checkPasswordLength () {
    return this.password.length >= 6
  },
  returnTransition () {
    if (this.transitionDirection === 'backwards') {
      return 'fadeBackwards'
    } else {
      return 'fade'
    }
  }
},
components: {
  'imask-input': IMaskComponent
},
mounted () {
  this.observer = new MutationObserver(mutations => {
    for (const m of mutations) {
      const newValue = m.target.getAttribute(m.attributeName);
      this.$nextTick(() => {
        this.onClassChange(newValue, m.oldValue);
      });
    }
  });
},
beforeDestroy () {
  this.observer.disconnect()
},
watch: {
  currentScreen: function (val, oldVal) {
    if (val === 'register') {
      this.callObserver()
    }
  },
  email: function (val, oldVal) {
    if (navigator.userAgent.search('Firefox') === -1) {
      if (document.getElementById('emailLoginInput').matches(':-webkit-autofill') || document.getElementById('emailLoginInput').matches(':autofill')) {
          this.$refs.loginForm.requestSubmit()
        }
    }
  },
  password: function (val, oldVal) {
    if (this.currentTab === 'email') {
      if (navigator.userAgent.search('Firefox') === -1) {
        if (document.getElementById('emailPasswordInput').matches(':-webkit-autofill') || document.getElementById('emailPasswordInput').matches(':autofill')) {
          this.$refs.emailPasswordForm.requestSubmit()
        }
      }
    } else if (this.currentTab === 'phone') {
      if (navigator.userAgent.search('Firefox') === -1) {
        if (document.getElementById('phonePasswordInput').matches(':-webkit-autofill') || document.getElementById('phonePasswordInput').matches(':autofill')) {
          this.$refs.phonePasswordForm.requestSubmit()
        }
      }
    }
  }
}
}
</script>

<style lang="scss" src="./login-page.scss"></style>

import Vue from 'vue';
import App from './components/App/App.vue';
import router from './router';
import store from './store';
import instance from '@/services/instance';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import VueApexCharts from 'vue-apexcharts'
import VueYandexMetrika from 'vue-yandex-metrika'
import vSelect from 'vue-select';
import SmartTable from 'vuejs-smart-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import OtpInput from '@bachdgvn/vue-otp-input'

import 'normalize.css';
import '@/static/styles/index.scss';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.config.productionTip = false;

instance.interceptors.response.use(undefined, function (err) {
  return new Promise((resolve, reject) => {
    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      store.dispatch('auth/AUTH_LOGOUT').then(() => {
        router.push('/')
      });
    }
    throw err;
  });
});

const getDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`;
}

function getMonthWord (index) {
  const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ]
  return months[index];
}

const getDateWithMonthWord = (date) => {
  const year = date.getFullYear();
  const month = getMonthWord(date.getMonth());
  const day = date.getDate();

  return `${day < 10 ? `0${day}` : day} ${month} ${year}`;
}

Vue.filter('dateFromTimeStamp', function (value) {
  if (!value) return '';
  const date = new Date(value * 1000);
  return getDate(date);
})

Vue.filter('dateWithMonthWord', function (value) {
  if (!value) return '';
  const date = new Date(value);
  return getDateWithMonthWord(date);
})

Vue.filter('date', function (value) {
  if (!value) return '';
  const date = new Date(value);
  return getDate(date);
})

Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(VueApexCharts);
Vue.use(VueYandexMetrika, {
  id: 90234224,
  router: router,
  env: process.env.NODE_ENV,
  scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
  debug: true
  // other options
})
Vue.use(SmartTable)

// noinspection JSCheckFunctionSignatures
Vue.component('apexchart', VueApexCharts)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.component('v-select', vSelect);
Vue.component(VueCountdown.name, VueCountdown)

Vue.component('v-otp-input', OtpInput);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

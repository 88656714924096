import instance from './instance';
import transactionTypeNames from './transactionsTypeNames';

export const apiClient = {
  // Вход
  authRequest: (user) => instance.post('/api/user/login/', user),

  // Регистрация
  regRequest: (data) => instance.post('/api/user/registration/', data),
  codeRequest: (data) => instance.post('/api/user/activate/', data),

  // Восстановление пароля
  restoreEmail: (email) => instance.get(`/api/user/password-recovery/?email=${email}`),
  restoreCode: (data) => instance.put('/api/user/password-recovery/', data),
  restorePass: (data) => instance.post('/api/user/password-recovery/', data),

  // Пользователь
  userProfilePic: () => instance.get('/api/user/profile/'),
  userRequisites: () => instance.get('/api/user/requisites/'),
  userPermissions: () => instance.get('/api/user/permissions/'),
  userSubscriptionInfo: () => instance.get('/api/user/subscription-info/'),
  userSubscriptions: () => instance.get('/api/user/subscriptions/'),
  priceProduct: () => instance.get('/api/user/price/'),
  cancelSubscription: () => instance.post('/api/user/subscriptions/cancel/'),
  forceUpdateSubscription: () => instance.post('/api/user/subscriptions/force-update/'),
  createSvoyRequest: (data) => instance.post('/api/club/request/', data),
  downloadPrice: (token) => instance.get(`/api/user/price/${token}/`, { responseType: 'blob' }),

  // CRM
  crmUsers: (params) => instance.get('/api/crm/user/', { params: params }),
  crmAddUser: (data) => instance.post('/api/crm/user/', data),
  crmUpdateUser: (id, data) => instance.patch(`/api/crm/user/${id}/`, data),
  crmDeleteUser: (id) => instance.delete(`/api/crm/user/${id}/`),
  crmProfit: () => instance.get('/api/crm/profit/'),
  crmProfitByMonth: () => instance.get('/api/crm/profit/by-month/'),
  crmProfitHistory: () => instance.get('/api/crm/profit/history/'),

  // Аналитика
  fetchCompanyExpenseData: (guid) => instance.get(`/api/analytics/company-expense/${guid}/`),
  fetchCompanyProfitData: (guid) => instance.get(`/api/analytics/company-profit/${guid}/`),
  downloadTransactionCsv: (guid) => instance.get(`/api/analytics/company-analytic-csv/${guid}/`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv',
        'Content-Disposition': 'attachment; filename=transactions.csv'
      }
    }
  ),

  downloadCompanyZip: (guid) => instance.get(`/api/analytics/company-zip/?id=${guid}`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip',
        'Content-Disposition': 'attachment; filename=company.zip'
      }
    }
  ),

  // Коды активации
  activationCodes: () => instance.get('/api/user/codes/'),

  // Компании
  fetchCompanies: () => instance.get('/api/user/company-list/?timestamp=1'),

  // Статьи расходов компании
  fetchExpenseCategories: (companyId) => instance.get(`/api/bill/list/?id=${companyId}&timestamp=1`),

  // Объекты
  fetchObjects: (companyId) => instance.get(`/api/object/list/?id=${companyId}&timestamp=111`),

  // Контрагенты
  fetchContractors: (companyId) => instance.get(`/api/contractor/list/?id=${companyId}&timestamp=123`),
  fetchContractorsCategories: (companyId) => instance.get(`/api/contractor/category/list/?timestamp=123&company_id=${companyId}`),

  // Транзакции
  fetchTransactions: (companyId) => {
    const getUrl = function (type) {
      return `/api/transaction/${type}/list/?id=${companyId}&timestamp=123`
    }

    const requests = transactionTypeNames.map(type => instance.get(getUrl(type)));
    return Promise.all(requests);
  },

  // Прайс-лист
  // категории
  fetchServices: (companyId) => instance.get(`/api/service/list/?id=${companyId}&timestamp=123`),
  fetchServicesCategories: (companyId) => instance.get(`/api/service/category/list/?id=${companyId}&timestamp=123`),
  addServiceCategory: (data) => instance.post('/api/service/category/create/', data),
  editServiceCategory: ({ title, guid }) => instance.patch(`/api/service/category/edit/${guid}`, { title }),
  removeServiceCategory: (guid) => instance.patch(`/api/service/category/edit/${guid}/`, { is_deleted: true }),

  // услуги
  addService: (data) => instance.post('/api/service/create/', data),
  setCategoriesPositions: (data) => instance.patch('/api/service/category/position/', data),
  setServicesPositions: (data) => instance.patch('/api/service/position/', data),
  removeService: (guid) => instance.patch(`/api/service/edit/${guid}/`, { is_deleted: true }),
  editService: (service) => instance.patch(`/api/service/edit/${service.guid}`, service),
  deleteAll: (companyGuid) => instance.delete(`/api/service/delete-all/${companyGuid}/`),

  // аутентификация
  checkLogin: (data) => instance.post('/api/auth/check-login/', data),
  signIn: (data) => instance.post('/api/auth/sign-in/', data),
  createProcess: (data) => instance.post('/api/auth/create-process/', data),
  requestCode: (login, config) => instance.get(`/api/auth/request-code/?login=${login}`, config),
  verifyCode: (config) => instance.get('/api/auth/verify-code/', config),
  createAccount: (data) => instance.post('/api/auth/create-account/', data),
  restorePassword: (data) => instance.post('/api/auth/restore-password/', data),

  // подписка
  createSubscription: (email, productID) => instance.post('/info/subscription-webhook/', {
    Email: email,
    payment: {
      products: [
        {
          id: productID,
          quantity: '1'
        }
      ]
    }
  })
}

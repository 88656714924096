<template>
  <div id="app" class="app">
    <template v-if="!oldDesign">
      <header class="app__header" @click="closeSideMenu">
        <Header @companyMenuHidden="hideCompanyMenu" @companyMenuExpanded="toggleCompanyMenu" :toggleHeaderMenu="toggleHeaderMenu" ref="HeaderChildRef" />
      </header>
      <div class="app__wrapper">
        <div class="app__wrapper-inner">
          <aside @click="closeHeaderMenu" v-if="!notSecuredRoutes.includes($route.name) && isAuthenticated" class="app__side-menu">
            <!-- <SideMenu @linkClicked="toggleSide" @menuExpanded="toggleSideMenu" ref="SideMenuChildRef" /> -->
            <SideMenu ref="SideMenuChildRef" />
          </aside>
          <main @click="closeHeaderMenu()" class="app__main-content">
            <router-view />
          </main>
        </div>
      </div>
      <div class="app__mobile-redirect">
        <MobileRedirect />
      </div>
    </template>
    <template v-if="oldDesign">
      <div class="app__holder">
        <aside
          @click="closeHeaderMenu"
          v-if="!notSecuredRoutes.includes($route.name) && isAuthenticated"
          class="app__aside"
          :class="{ 'app__aside_open': isMenu }"
        >
          <button class="app__close" @click="switchMenu">
            закрыть
          </button>
          <Side />
          <!-- <Button @click.native="$router.push({ name: 'preliminary' })" class="app__preliminary" theme="auth">
            Предварительный расчет
          </Button> -->
        </aside>
        <main @click="closeHeaderMenu(), closeSideMenu()" class="app__main">
          <div class="app__mobile-menu" :class="$route.name === 'price' ? 'app__mobile-menu_white' : ''">
            <button
              v-if="!isMenu && isAuthenticated"
              @click="switchMenu"
              class="app__menu"
            >
              <img class="app__menu-icon" :src="require('./assets/menu.png')" alt="">
            </button>
            <GotoSubscription :is-authenticated="isAuthenticated" />
            <GotoBuyPrice :is-authenticated="isAuthenticated" />
          </div>
          <router-view />
        </main>
      </div>
    </template>
  </div>
</template>

<script>
import Side from '@/components/Side/Side';
import Header from '@/components/Header/Header';
import SideMenu from '@/components/SideMenu/SideMenu';
import { mapState, mapActions, mapGetters } from 'vuex';
import GotoSubscription from '@/components/GotoSubscription/GotoSubscription.vue';
import GotoBuyPrice from '@/components/GotoBuyPrice/GotoBuyPrice.vue';
import MobileRedirect from '@/components/MobileRedirect/MobileRedirect.vue';

export default {
  data () {
    return {
      notSecuredRoutes: [
        'login', 'confirm-code', 'restore-pass'
      ],
      oldDesign: false,
      companyMenuExpanded: false,
      sideMenuExpanded: false
    }
  },

  computed: {
    ...mapState('common', {
      isMenu: state => state.isMenu
    }),
    ...mapGetters('auth', ['isAuthenticated'])
  },

  components: {
    GotoSubscription,
    GotoBuyPrice,
    Side,
    Header,
    SideMenu,
    MobileRedirect
  },

  watch: {
    sideMenuExpanded () {
      if (this.sideMenuExpanded) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    companyMenuExpanded (newVal) {
      if (newVal || this.companyMenuExpanded) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },

  methods: {
    ...mapActions('common', [
      'toggleMenu'
    ]),
    switchMenu: function () {
      const { isMenu, toggleMenu } = this;
      if (isMenu) {
        document.body.style.overflow = 'auto';
      } else {
        document.body.style.overflow = 'hidden';
      }
      toggleMenu()
    },
    closeHeaderMenu: function () {
      const childComponent =
        this.$refs.HeaderChildRef;

        if (this.$route.name !== 'login') {
          if (childComponent.isListOpen) {
            childComponent.hideMenu();
        }
        }
    },
    closeSideMenu: function () {
      const childComponent =
        this.$refs.SideMenuChildRef;
        if (this.$route.name !== 'login') {
          if (childComponent.menuExpanded && this.$route.name !== 'login') {
            childComponent.toggleMenu();
          }
        }
    },
    toggleHeaderMenu: function () {
      const childComponent = this.$refs.HeaderChildRef;
      if (this.$route.name !== 'login' && childComponent.isListOpen) {
        childComponent.toggleList()
      }
    },
    // toggleSideMenu: function (menuExpanded) {
    //   this.sideMenuExpanded = menuExpanded
    // },
    // toggleSide: function () {
    //   if (this.sideMenuExpanded) {
    //     this.sideMenuExpanded = !this.sideMenuExpanded
    //   }
    // },
    toggleCompanyMenu: function (menuExpanded) {
      this.companyMenuExpanded = menuExpanded
    },
    hideCompanyMenu: function () {
      this.companyMenuExpanded = !this.companyMenuExpanded
    }
  }
}
</script>

<style lang="scss" src="./app.scss"></style>

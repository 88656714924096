<template>
    <div class="header">
        <div class="header__inner">
            <img :src="require('./assets/101.jpeg')" alt="Логотип 101" class="header__icon">
            <div v-if="!notSecuredRoutes.includes($route.name)" class="header__company">
                <button @click="toggleList"
                class="header__company-btn">
                    <HeaderCompany
                        :title="selectedId ? companies[selectedId].title : null"
                        :imgSrc="selectedId ? companies[selectedId].logo_url : null"
                    />
                </button>
                <div :class="{'menu-expanded': isListOpen}">
                  <HeaderCompanyMenu
                    :title="selectedId ? companies[selectedId].title : null"
                    :imgSrc="selectedId ? companies[selectedId].logo_url : null"
                    :toggleList="toggleList"
                    @logout="$modal.show('logout-modal')"
                  >
                    <li class="company__menu-list-element">
                        <div class="company__companies-list">
                          <ul :class="{ 'bottom-fade': checkCompanies}" @click="toggleList" class="company__menu-inner-companies-list">
                              <li
                                  class="company__menu-inner-list-item" v-for="item in companiesIds"
                                  :key="item"
                                  :selected="selectedId === item"
                                  @click="handleSelect(item), toggleList()"
                              >
                                  <button class="company-btn">
                                    <div class="logo">
                                      <CompanyLogoInner :imgTitle="companies[item].title" :imgSrc="companies[item].logo_url ? companies[item].logo_url : null" />
                                    </div>
                                    <div class="company-info">
                                      <div class="company-title">{{ companies[item].title }}</div>
                                       <div class="user-role">{{ companies[item].user_type_for_web }}</div>
                                    </div>
                                  </button>
                              </li>
                          </ul>
                      </div>
                      <!-- <button class="add-company">
                        <img src="./assets/plus.png" alt="Добавить">
                        Добавить компанию
                      </button> -->
                    </li>
                  </HeaderCompanyMenu>
                </div>
            </div>
        </div>
        <LogoutModal @close="$modal.hide('logout-modal')" @logout="logout()" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompanyLogoInner from '@/components/CompanyLogoInner/CompanyLogoInner'
import HeaderCompany from '@/components/HeaderCompany/HeaderCompany'
import HeaderCompanyMenu from '@/components/HeaderCompanyMenuNew/HeaderCompanyMenu'
import LogoutModal from './components/LogoutModal/LogoutModal.vue'

export default {
    data () {
      return {
          notSecuredRoutes: [
            'login', 'confirm-code', 'restore-pass'
          ],
          isListOpen: false,
          isMenuClicked: false
      }
    },
    props: {
      toggleHeaderMenu: Function
    },
    components: {
        CompanyLogoInner,
        HeaderCompany,
        HeaderCompanyMenu,
        LogoutModal
    },
    computed: {
    ...mapState('companies', {
      companiesIds: state => state.ids,
      companies: state => state.data,
      selectedId: state => state.selectedId
    }),
    checkCompanies () {
      return this.companiesIds.length > 4
    }
  },
  mounted () {
    this.$root.$emit('eventing', this.toggleList);
  },
  methods: {
    ...mapActions('auth', [
      'AUTH_LOGOUT'
    ]),
    ...mapActions('companies', [
      'SELECT_COMPANY'
    ]),

    logout: function () {
      this.AUTH_LOGOUT()
      .then(() => {
        this.$router.push('/login')
      })
    },

    handleSelect: function (id) {
      if (id !== this.selectedId) {
        this.SELECT_COMPANY(id);
      }

      this.toggleList();
    },

    toggleList: function () {
      if (this.isMenuClicked === true) {
        this.isListOpen = true
      }
      this.isListOpen = !this.isListOpen
      this.$emit('companyMenuExpanded', this.isListOpen)
    },

    hideMenu: function () {
      // this.isMenuClicked = true
      if (this.isListOpen) {
        this.isListOpen = false
      }
      this.$emit('companyMenuHidden')
    }
  }
}
</script>

<style lang="scss" src="./header.scss"></style>

import { extend } from 'vee-validate';
import { email, alpha } from 'vee-validate/dist/rules';

extend('phone', {
    validate (value) {
        return value.length === 10;
    },
    params: ['length'],
    message: 'Телефон введён некорректно'
});

extend('required', {
    validate (value) {
        return value.length > 0;
    },
    params: ['length'],
    message: 'Поле не может быть пустым'
});

extend('email', {
    ...email,
    message: 'Почта введена некорректно'
});

extend('alpha', {
    ...alpha,
    message: 'Поле не может содержать цифры'
});

extend('verifyCode', {
    validate (value, params) {
      return true;
    },
    message: 'Введён неверный код подтверждения. Пожалуйста, проверьте код и попробуйте ещё раз'
});

extend('verifyPassword', {
    validate (value, params) {
        // return value === params[0];
      return true;
    },
    message: 'Неверный пароль. Повторите попытку или нажмите на\u00a0ссылку "Не\u00a0помню пароль", чтобы сбросить его'
});

extend('minPassword', {
    validate (value) {
        return value.length >= 6;
    },
    params: ['length'],
    message: 'Пароль должен содержать не менее 6 символов'
});

<template>
  <Modal name="logout-modal">
    <div class="modal-holder">
      <div class="heading">Вы действительно хотите выйти из аккаунта?</div>
      <div class="buttons">
        <button class="transparent" @click="$emit('close')">Отменить</button>
        <button @click="handleLogout">Выйти</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/HeaderModal/Modal';
import { mapActions } from 'vuex';

export default {
  components: {
    Modal
  },
  methods: {
    ...mapActions('auth', [
      'AUTH_LOGOUT'
    ]),
    handleLogout () {
      this.AUTH_LOGOUT().then(() => {
        this.$emit('close');
        this.$router.push('/login');
      });
    }
  }
}
</script>

<style src="./logout-modal.scss" lang="scss" scoped>

</style>
